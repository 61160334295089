<template>
  <div class="page-login-desktop">

    <VerifyForm
        v-if=" verify"
        :loading="loading"
        :timeout="user_verification_lifetime"
        :phone-number="phoneNumber"
        @onSubmit="verification"
        @onReSubmit="login"
        @onBack="backToLogin"
    />

    <LoginForm
        :edit-phone-number="phoneNumber"
        v-if="!verify"
        :loading="loading"
        @onSubmit="login"
    />


  </div>
</template>

<script>


import LoginForm from "./LoginForm";
import {login,  verification} from "@Newfiling/module-auth/src/api";
import VerifyForm from "./VerifyForm";
import {getPermissions} from "@Newfiling/api";

export default {
  name: 'DesktopLogin',

  components: {VerifyForm, LoginForm},

  data() {
    return {
      loading: false,
      verify: false,
      phoneNumber: "",
      user_verification_lifetime: "60",
      tracking_id: "",
      userExists: true,
    }
  },

  mounted() {
    this.$vuetify.goTo('body')
    this.$store.commit('User/resetUser')
  },

  methods: {

    async login(phone_number) {
      this.phoneNumber = phone_number;
      this.loading = true;
      try {
        const res = (await login({
          "mobile": phone_number,
          "Sitenumber": "1",
          "adminlogin": true
        }))?.data?.message || {};

        this.verify = true;
        this.tracking_id = res.id;
        this.userExists = res.userexist !== -1;
        if (res.message) {
          this.$toast.warning(res?.message);
        } else {
          this.$toast.success('کد تایید برای شما ارسال شد.');
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },

    async verification(code) {
      this.loading = true;
      const deviceId = this.$store.getters['User/getDeviceId'];
      try {
        const res = (await verification({
          id: parseInt(this.tracking_id),
          code,
          deviceId: deviceId
        }))?.data || {};
        this.$store.commit('User/setUser', res?.message);
        const permission = (await getPermissions())?.data?.userlevels || [];
        console.log({permission})
        const allowPermissions =  permission.find(item=>{
          return  item.accountType === this.$store.getters['User/getUser']?.accountType
        })?.accessiblepagesTB;
        await this.$store.commit('User/setPermissions',allowPermissions)
        const redirectUrl =  '/panel/dashboard';

        await this.$router.replace({
          path: redirectUrl,
          query: this.$route.query
        })

      } catch (e) {
        console.log(e)
      }
      this.loading = false;
    },

    backToLogin() {
      this.verify = false;
    },

  }
}
</script>

<style scoped>
.page-login-desktop {
  height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
  url('/img/background.jpg');
  background-position: center center;
  background-size: cover !important;
}
</style>
