import { httpProvider } from "@Newfiling/Services";

export const login = (data) => {
    return httpProvider.post('/eLogin', data);
};

export const verification = (data) => {
    return httpProvider.post('/elogin/codeverify', {
        ...data,
    });
};

export const logout = () => {
    return httpProvider.post('/logout', {});
};

export const verificationCode = (params) => {
    return httpProvider.post('/Admin/VerifyCodeslist', {
        "skip": params.skip,
        "take": params.take
    });
};
